








































































import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { dispatchActivateUser } from '@/store/admin/actions';
import {IUserProfileUpdate} from '@/interfaces/index';

@Component
export default class Login extends Vue {
  public password = '';
  public password_confirm = '';
  public appName = appName;
  
  public rules = {
    passwordRules: [
      (value) => !!value || 'Please type password.',
      (value) => (value && value.length >= 6) || 'minimum 6 characters',
    ],
  }

  public submit() {
    if((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      dispatchActivateUser(
        this.$store, 
        { 
          confirmation_code: this.$route.params.confirmation_code, 
          user: {
            password: this.password,
            is_active: true,
          }
        });
      this.$router.push('/login');
    }
  }
}
